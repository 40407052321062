<template>
  <div>
    <Breadcrumbs title="Student Attendance Report" main="Reports"/>
    <div class="container-fluid">
      <b-row>
        <b-col cols="12">
          <div class="card">
            <div class="card-body">

              <b-row>
                <b-col cols="12" lg="6" xl="3">
                  <b-form-group label="Institute">
                    <b-form-select @change="fetchTeachers" v-model="formSearch.institute_id" :options="institutes" value-field="id" text-field="text" class="btn-square border"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6" xl="3">
                  <b-form-group label="Teacher">
                    <b-form-select @change="fetchClasses" v-model="formSearch.teacher_id" :options="teachers" value-field="id" text-field="text" class="btn-square border"></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" xl="6">
                  <b-form-group label="Class">
                    <b-form-select @change="clearResult()" v-model="formSearch.class_detail_id" :options="class_details" value-field="id" text-field="text" class="btn-square border" required></b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6" xl="3">
                  <b-form-group label="Start Date *">
                    <b-form-datepicker @input="clearResult()" reset-button close-button v-model="formSearch.start_date"></b-form-datepicker>
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="6" xl="3">
                  <b-form-group label="End Date *">
                    <b-form-datepicker @input="clearResult()" reset-button close-button v-model="formSearch.end_date" class="btn-square border"></b-form-datepicker>
                  </b-form-group>
                </b-col>
              </b-row>

              <b-row>
                <b-col cols="12" class="text-center">
                  <b-button @click="generateReport" :disabled="!formSearch.start_date || loading" size="sm" class="btn btn-square text-uppercase btn-primary m-2">Generate</b-button>
                  <b-button @click="printReport('print')" :disabled="!totalRows || loading" size="sm" class="btn btn-square text-uppercase btn-success m-2">Print</b-button>
                </b-col>
              </b-row>

            </div>
          </div>

          <div class="card">
            <div class="card-body" id="print">
              <div class="text-center">

                <div class="text-center">
                  <h2 class="card-title">Student Attendance Report</h2>
                </div>

                <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">Institute</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{ institutes.filter(record => record.id===formSearch.institute_id).map(record => record.text)[0] }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">Teacher</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{ teachers.filter(record => record.id===formSearch.teacher_id).map(record => record.text)[0] }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">Class</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{ class_details.filter(record => record.id===formSearch.class_detail_id).map(record => record.text)[0] }}</b-col>
                </b-row>
                <b-row>
                  <b-col cols="4" md="3" lg="2" class="text-left">Payment Period</b-col>
                  <b-col cols="8" md="9" class="text-left">: {{formSearch.start_date || "N/A"}} to {{formSearch.end_date || "N/A"}}</b-col>
                </b-row>

                <div class="table-responsive datatable-vue" v-show="!loading">
                  <b-table
                      ref="table"
                      show-empty
                      :items="tableRecords"
                      :fields="tableFields"
                      :filter="filter"
                      :current-page="currentPage"
                      :per-page="perPage"
                  >

<!--                    <template #cell(index)="row">-->
<!--                      {{ (1+row.index) }}-->
<!--                    </template>-->
<!--                    <template #cell(student_details)="row">-->
<!--                      <p class="m-0">{{row.item.student.first_name}} {{row.item.student.last_name}}</p>-->
<!--                      <p class="m-0">{{row.item.student.nic}}</p>-->
<!--                    </template>-->
<!--                    <template #cell(class_detail)="row">-->
<!--                      <p class="m-0">{{row.item.class_schedule.class_detail.name}}</p>-->
<!--                      <p class="m-0">{{row.item.institute.name}}</p>-->
<!--                    </template>-->
<!--                    <template #cell(marked_time)="row">-->
<!--                      {{ moment(row.item.created_at).format('llll') }}-->
<!--                    </template>-->

                    <template #cell(index)="row">
                      {{ (1+row.index) }}
                    </template>
                    <template #cell(student_details)="row">
                      <p class="m-0">{{row.item.student_name}}</p>
                      <p class="m-0">{{row.item.student_nic}}</p>
                    </template>
                    <template #cell(class_detail)="row">
                      <p class="m-0">{{row.item.class_name}}</p>
                      <p class="m-0">{{row.item.institute_name}}</p>
                    </template>
                    <template #cell(marked_time)="row">
                      {{ moment(row.item.created_at).format('llll') }}
                    </template>

                  </b-table>
                </div>



              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {checkUserPermissions} from "@/plugins/functions";
import API from '@/services/api'
import CRUD_API from '@/services/crud'
import { Printd } from 'printd'
export default {
  name: "student_attendance",
  computed: mapState({ user: state => JSON.parse(state.auth.user) }),
  data() {
    return {
      api_base: '/api/backend/reports/student-attendance',
      filter: '',
      totalRows: 0,
      perPage: 100,
      lastPage: 1,
      currentPage: 1,
      tableRecords: [],
      loading: false,


      tableFields: [
        { key: 'index', label: '#', sortable: false, },
        { key: 'student_details', label: 'Student', sortable: true, class: 'text-left' },
        { key: 'class_detail', label: 'Class Details', sortable: true, class: 'text-left' },
        { key: 'marked_time', label: 'Date/Time', sortable: true, },
        { key: 'seat_number', label: 'Seat No.', sortable: false, },
        { key: 'note', label: 'Remarks', sortable: false, class: 'text-left' },
        // { key: 'action', label: 'Action', sortable: false, class: 'text-right text-capitalize' }
      ],

      report_total: 0,
      institute_share: 0,
      teacher_share: 0,
      discount_total: 0,
      lecturer_name: 'N/A',
      summaryTableFields: [
        { key: 'id', label: "", class: 'text-left' },
        { key: 'value', label: "", class: 'text-right' },
      ],
      summaryTableRecords: [
        { id: "Total: ", value: 0 },
        { id: "Institute Share: ", value: 0 },
        { id: "Teacher Share: ", value: 0 },
      ],

      institutes: [],
      subjects: [],
      teachers: [],
      cashiers: [],
      class_details: [],
      formSearch: {
        start_date:'',
        end_date: '',
        institute_id: null,
        cashier_id: null,
        teacher_id: null,
        subject_id: null,
        class_detail_id: null,
        student_nic: '',
        year: new Date().getFullYear()
      },

    }
  },
  mounted() {
    this.fetchInstitutes()
    this.fetchCashiers()
    this.fetchTeachers()
  },
  watch: {
    lecturer_name()  {
      if (this.formSearch.teacher_id === null) {
        return "N/A"
      }
      return this.teachers.filter(user => user.id===this.formSearch.teacher_id).map(user => user.text)[0]
    },
  },
  methods: {
    checkUserPermissions,
    clearResult(){
      this.tableRecords = []
    },
    async fetchInstitutes() {
      this.institutes = []
      await API.select('institutes', {
        status: 0
      }).then((result) => {
        if(result.data.data.length) {
          this.institutes = result.data.data
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.institutes.unshift({
          id: null, text: "All Institutes"
        })
        this.fetchClasses()
      })
    },
    async fetchCashiers() {
      this.cashiers = []
      await API.select('cashiers', {
        status: 0
      }).then((result) => {
        if(result.data.data.length) {
          this.cashiers = result.data.data
        }
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.cashiers.unshift({
          id: null, text: "All Cashiers"
        })
        this.fetchClasses()
      })
    },
    async fetchTeachers() {
      this.clearResult()
      this.teachers = []
      await API.select('teachers', {
        institute_id: this.formSearch.institute_id
      }).then((result) => {
        this.teachers = result.data.data
      }).catch((error) => {
        console.log(error)
      }).finally(() => {
        this.teachers.unshift({
          id: null, text: "All Teachers"
        })
        this.fetchClasses()
      })
    },
    async fetchClasses() {
      this.clearResult()
      this.class_details = []
      this.formSearch.class_detail_id = null
      await API.select('classes', {
        institute_id: this.formSearch.institute_id,
        teacher_id: this.formSearch.teacher_id,
      }).then(result => {
        this.class_details = result.data.data
      }).catch(error => {
        console.log(error)
      }).finally(() => {
        this.class_details.unshift({
          id: null, text: "All Classes"
        })
      })
    },
    printReport(el) {
      const d = new Printd()
      d.print(document.getElementById(el), [`
          * { font-family: serif; font-size: 12px; },
          @page { margin: 2.5cm; }
          div.row > div { display: inline-block; }
          div.row { display: block; }
          h2 { font-size: 18px; text-align:center; }
       `])
    },
    async generateReport() {
      this.tableRecords = []
      this.currentPage = 0
      this.report_total = 0
      this.totalRows = 0
      this.institute_share = 0
      this.teacher_share = 0
      this.discount_total = 0
      this.loading = true

      await CRUD_API.index(this.api_base, {
        params: {
          institute_id: this.formSearch.institute_id,
          teacher_id: this.formSearch.teacher_id,
          class_detail_id: this.formSearch.class_detail_id,
          start_date: this.formSearch.start_date,
          end_date: this.formSearch.end_date,
        }
      }).then(result => {
        this.tableRecords = result.data.data.data
        this.totalRows = result.data.data.total
        this.perPage = this.totalRows
      }).catch(error => {
        console.log(error)
        this.loading = false
        this.$toasted.error(error.message)
      }).finally(() => {
        this.loading = false
      })
    },
  }
}
</script>

<style scoped>

</style>
